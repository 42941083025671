import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import ServicesDescription from './components/ServicesDescription';
import Portfolio from './pages/Portfolio';
import Contact from './pages/Contact';
import Careers from './pages/Careers';
import JobDetail from './pages/JobDetail';
import Blogs from './pages/Blogs';
import Benefits from './pages/Benefits';
import Culture from './pages/Culture';
import Footer from './components/Footer';
import FooterDisplayPage from './pages/FooterDisplayPage';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import CookiesPolicy from './pages/CookiesPolicy';
import Dashboard from './pages/DashboardPage';
import AdminDashboard from './pages/AdminDashboard';
import HRDashboard from './pages/HrDashboard';
import EmployeeManagement from './components/EmployeeManagement';
import KanbanBoard from './components/KanbanBoard';
import MeetingScheduler from './components/MeetingScheduler';
import Messaging from './components/Messaging';
import PerformanceReports from './components/PerformanceReports';
import ProjectsPage from './pages/ProjectsPage';
import ReportsPage from './pages/ReportsPage';
import CalendarPage from './pages/CalendarPage';
import NotificationsPage from './pages/NotificationsPage';
import SettingsPage from './pages/SettingsPage';
import ForgotPass from './pages/ForgotPass';
import ATSscore from './components/ATSscore';
// import Arrow from './components/Arrow';
// import Banners from './components/Banners';
// import DactivityFeed from './components/DactivityFeed';
// import Dheader from './components/Dheader';
// import Dsidebar from './components/Dsidebar';
// import Dwidgets from './components/Dwidgets';
// import Robot from './components/Robot';
import './styles/App.css';

const PrivateRoute = ({ children, allowedRoles }) => {
    const token = localStorage.getItem('token');
    const userRole = localStorage.getItem('userRole');

    if (!token) {
        return <Navigate to="/login" replace />;
    }

    if (allowedRoles && !allowedRoles.includes(userRole)) {
        return <Navigate to="/" replace />;
    }

    return children;
};

const App = () => {
    return (
        <Router>
            <div className="App">
                <Navbar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/signup" element={<SignUpPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/reset-password/:token" element={<ForgotPass />} />
                    <Route path="/dashboard" element={
                        <PrivateRoute allowedRoles={['admin', 'hr', 'user']}>
                            <Dashboard />
                        </PrivateRoute>
                    } />
                    <Route path="/admin-dashboard" element={
                        <PrivateRoute allowedRoles={['admin']}>
                            <AdminDashboard />
                        </PrivateRoute>
                    } />
                    <Route path="/hr-dashboard" element={
                        <PrivateRoute allowedRoles={['hr']}>
                            <HRDashboard />
                        </PrivateRoute>
                    } />
                    <Route path="/about" element={<About />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/services/:serviceId" element={<ServicesDescription />} />
                    <Route path="/portfolio" element={<Portfolio />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/careers" element={<Careers />} />
                    <Route path="/careers/:id" element={<JobDetail />} />
                    <Route path="/dashboard/employees" element={
                        <PrivateRoute allowedRoles={['admin', 'hr']}>
                            <EmployeeManagement />
                        </PrivateRoute>
                    } />
                    <Route path="/dashboard/projects" element={
                        <PrivateRoute allowedRoles={['admin', 'hr', 'user']}>
                            <ProjectsPage />
                        </PrivateRoute>
                    } />
                    <Route path="/dashboard/reports" element={
                        <PrivateRoute allowedRoles={['admin', 'hr']}>
                            <ReportsPage />
                        </PrivateRoute>
                    } />
                    <Route path="/dashboard/calendar" element={
                        <PrivateRoute allowedRoles={['admin', 'hr', 'user']}>
                            <CalendarPage />
                        </PrivateRoute>
                    } />
                    <Route path="/dashboard/notifications" element={
                        <PrivateRoute allowedRoles={['admin', 'hr', 'user']}>
                            <NotificationsPage />
                        </PrivateRoute>
                    } />
                    <Route path="/dashboard/settings" element={
                        <PrivateRoute allowedRoles={['admin', 'hr', 'user']}>
                            <SettingsPage />
                        </PrivateRoute>
                    } />
                    <Route path="/blogs" element={<Blogs />} />
                    <Route path="/benefits" element={<Benefits />} />
                    <Route path="/culture" element={<Culture />} />
                    <Route path="/footer/:itemId" element={<FooterDisplayPage />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-of-service" element={<TermsOfService />} />
                    <Route path="/cookies-policy" element={<CookiesPolicy />} />
                    <Route path="/ats-scores" element={<ATSscore />} />
                    <Route path="/kanban" element={<KanbanBoard />} />
                    <Route path="/meetings" element={<MeetingScheduler />} />
                    <Route path="/messages" element={<Messaging />} />
                    <Route path="/performance" element={<PerformanceReports />} />
                </Routes>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
